@import '../../../styles/variables.scss';

.next-retailer .stampcard-editor {
  .main-content {
    .section-split {
      .left {
        flex-grow: 2;
        flex-shrink: 2;
        > .box {
          height: 100%;
        }
      }
      .right {
        > .box {
          height: 100%;
        }
      }
    }
  }

  .product-picker {
    .button-container {
      display: flex;
      justify-content: center;
      gap: 2em;
      > div {
        flex: 0 0 20em;
      }
    }
  }

  .reward-configuration {
    .reward-image-preview {
      margin: $margin-double;
      display: flex;
      background-color: $color-lightest-grey;
      justify-content: space-between;
      align-items: center;
      border-radius: $border-radius;
      &.reward-type-product {
        flex-direction: row;
        .reward-image-container {
          display: flex;
          flex: 1 1 auto;
          margin: $margin-half;
          justify-content: center;
          img {
            margin: 0;
            max-width: 100px;
            max-height: 100px;
          }
        }
        .reward-name-container {
          flex: 1 1 auto;
          margin: $margin-default;
          font-family: $font-default-bold;
          width: 150px;
          overflow-wrap: break-word;
        }
        .reward-discount-container {
          display: flex;
          flex: 0 0 auto;
          flex-direction: column;
          margin: $margin-default;
          color: $color-k-orange;
          .reward-discount-text-top {
            text-align: right;
          }
          .reward-discount-text-free {
            text-align: right;
            font-family: $font-default-bold;
          }
          .reward-discount-value-container {
            display: flex;
            flex: 0 0 auto;
            flex-direction: row;
            justify-content: right;
            .reward-discount-value-euros {
              font-size: $font-size-xxxxl;
              flex: 0 0 auto;
              font-weight: bold;
            }
            .reward-discount-cents-container {
              display: flex;
              flex: 0 0 auto;
              flex-direction: column;
              justify-content: center;
              .reward-discount-value-cents {
                font-weight: bold;
                flex: 0 0 auto;
              }
              .reward-discount-value-unit {
                font-size: $font-size-xs;
                flex: 0 0 auto;
              }
            }
          }
        }
      }
      &.reward-type-basket {
        flex-direction: column;
        .reward-image-container {
          flex: 1 1 auto;
          img {
            margin: 0;
            max-width: 100px;
            max-height: 100px;
          }
        }
        .reward-name-container {
          flex: 1 1 auto;
          margin-bottom: $margin-default;
          font-weight: bold;
        }
      }
    }
  }

  .stampcard-preview {
    .button-container {
      display: flex;
      justify-content: center;
      > .btn {
        max-width: 20em;
      }
    }
  }

  hr {
    margin: $margin-default 0;
    border: 1px solid $color-light-grey;
  }

  .stampcard-appearance {
    .button-container {
      margin-top: $margin-default;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .stampcard-preview-container {
    display: flex;
    justify-content: center;
    padding: $padding-default;
    border-radius: $border-radius;
    background-color: $color-lightest-grey;
    .stampcard-preview {
      max-width: 400px;
      padding: 0;
      border-radius: $border-radius * 5;
      background-color: white;
      box-shadow:
        2px 2px 10px 0px $color-grey,
        0px 0px 2px 0px $color-light-grey;
      .stampcard-preview-image {
        border-top-left-radius: $border-radius * 5;
        border-top-right-radius: $border-radius * 5;
        width: 100%;
      }
      .stampcard-details-container {
        padding: calc($padding-default / 2);
        padding-left: $padding-default;
        .stampcard-details-name {
          font-weight: bold;
        }
      }
      .stampcard-details-stores {
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size-xs;
        font-weight: bold;
        gap: calc($padding-default / 2) $padding-default;
        margin-top: $margin-default;
        margin-bottom: calc($margin-default / 2);
        .stampcard-details-store {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 0 0 auto;
          min-height: 0px;
          min-width: 0px;
          img {
            height: 20px;
          }
        }
      }
    }
  }

  .stampcard-summary {
    h2 {
      margin-top: $margin-default;
      font-family: $font-default-bold;
      color: $color-dark-grey;
    }
  }

  .stampcard-reward-summary {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: $margin-double;

    > .condition,
    .reward {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-size: $font-size-xl;
      }
      .highlight {
        color: $color-k-orange;
      }
    }
  }

  .footer {
    .footer-buttons {
      display: flex;
      justify-content: center;
    }

    text-align: center;
    .btn,
    a {
      display: inline-block;
    }
    .btn {
      margin: 12px 6px;
    }
  }

  .mobile-footer {
    margin-bottom: $margin-half;
    .footer-buttons {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: stretch;
      .btn {
        &.btn-bordered {
          background: white;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 $margin-quarter $margin-half;
        flex-grow: 1;
        flex-basis: 0;
        min-height: 5em;
        text-align: center;
      }
    }
  }
}
